import qs from 'qs'

export const parseQueryString = string => {
  if (!string || typeof string !== 'string') {
    return {}
  }

  const query = {}

  const params = string.split(/\?(.+)/)[1]

  if (!params) {
    return {}
  }

  if (params.includes('redirectRoute')) {
    const [key, value] = params.split(/=(.[^#]+)/)
    query[key] = decodeURIComponent(value)
  } else {
    // We have to use this regex to capture situations where the url is formatted elsewhere (e.g. Alipay)
    // and wraps the hash route with two separate query string, e.g:
    // http://localhost:8080/?client_secret=src_client_secret_FqWKDH0jBjOkkfLi0HBolUCJ&livemode=false&source=src_1FKpHrKWfSycpO9Egqi08sLP#/registrations?et=4c6b94dbb5b352107c1c0a2738411e71&lang=zh
    params.split(/[&?]/).forEach(param => {
      const [key, value] = param.split(/=(.[^#]+)/)
      query[key] = decodeURIComponent(value)
    })
  }

  return query
}

// Function to return all parameters after the ? in a URL
// E.g.: Given Hash with #/supplier?term=Cheese
// this function will return { term: 'Cheese' }
export const getUrlVarsFromHash = () => {
  const query = window.location.hash.slice(window.location.hash.indexOf('?'))

  return parseQueryString(query)
}

export const getPathnameFromFullHashUrl = url => {
  const fullUrl = new URL(url)

  return fullUrl.hash ? fullUrl.hash.substring(1) : null
}

export const redirectToKWS = product => {
  const { asin, country } = product
  const queryParams = qs.stringify({
    marketplace: country,
    asin
  })
  window.location.hash = `keyword?${queryParams}`
}

export const redirectKeywordToKWS = (keyword, country) => {
  const queryParams = qs.stringify({
    marketplace: country,
    keyword
  })
  const url = window.location.toString()
  const location = url.split('#')[0]
  window.open(`${location}#/keyword?${queryParams}`, '_blank')
}

export const isChinaDomain = () => window.location.hostname.endsWith('.cn')

export const stripURLParams = currentURL => {
  try {
    const url = new URL(currentURL)
    let { pathname } = url
    if (url.hash) {
      pathname += url.hash
    }
    const baseUrl = `${url.protocol}//${url.host}${pathname}`
    if (baseUrl.split('?').length > 1) {
      return baseUrl.split('?')[0]
    }
    return baseUrl
  } catch (error) {
    return ''
  }
}

/*
  Returns the current URL search params and a function to overwrite given searchParams.
*/
export const urlParams = () => {
  const href = window.location?.href || ''
  const searchParams = new URLSearchParams(href.split('?')[1])

  const setSearchParams = value => {
    const baseURL = href.split('?')[0]
    if (value && Array.from(value).length > 0) {
      window.location.href = `${baseURL}?${searchParams}`
    } else {
      window.location.href = baseURL
    }
  }

  return {
    searchParams,
    setSearchParams
  }
}

/**
 * Opens a URL in a new tab.
 */
export const openURL = url => {
  window.open(url, '_blank', 'noreferrer')
}
